import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans font-light text-gray-700 bg-white">
      <Header />
      {children}
    </div>
  );
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
