import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import LogoImage from 'src/images/logo.png';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="relative bg-white shadow">
      <div className="flex justify-between mx-6 my-4 md:m-0 md:flex-col items-center">

        {/* Logo */}
        <div className="md:my-6">
          <Link to="/">
            <img src={LogoImage} alt="TinyTots PT" className="h-12 md:h-auto" style={{ maxWidth: '240px' }} />
          </Link>
        </div>
        <NavOpenIcon onClick={() => toggleExpansion(!isExpanded)} />

        {/* Nav wrapper */}
        <div
          className={
            `${isExpanded ? `flex` : `hidden`} ` +
            'items-center justify-center bg-transparent absolute m-4 top-0 left-0 right-0 ' +
            'md:flex md:w-full md:bg-gray-100 md:relative md:m-auto md:top-auto md:left-auto md:right-auto md:p-3'
          }
        >
          {/* Overlay */}
          <div
            className="fixed md:hidden top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 z-20"
            onClick={() => toggleExpansion(!isExpanded)}
          >
          </div>

          {/* Nav */}
          <nav
            className={
              'relative block w-full bg-white rounded shadow pt-8 pb-4 z-20 ' +
              'md:w-auto md:bg-transparent md:rounded-none md:shadow-none md:pt-0 md:pb-0'
            }
          >
            <NavLink to="/" text="Home" />
            <NavLink to="/about" text="About Me" />
            <NavExternalLink to="https://blog.tinytotspt.com" text="Blog" />
            <NavExternalLink to="https://blog.tinytotspt.com/resource-library" text="Resource Library" />
            <NavLink to="/disclaimer" text="Disclaimer" />
            <NavLink to="/contact" text="Contact" />

            <NavCloseIcon isExpanded={isExpanded} onClick={() => toggleExpansion(!isExpanded)} />
          </nav>
        </div>
      </div>
    </header>
  );
}

const NavOpenIcon = ({ onClick }) => (
  <button className="flex items-center block md:hidden" onClick={onClick}>
    <svg
      viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6"
      strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
    >
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </svg>
  </button>
);
NavOpenIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const NavCloseIcon = ({ isExpanded, onClick }) => (
  <button className={`${isExpanded ? 'block' : 'hidden'} md:hidden absolute top-0 right-0 mt-4 mr-4`} onClick={onClick}>
    <svg
      viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6"
      strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </button>
);
NavCloseIcon.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const NavLink = ({ to, text }) => (
  <Link
    to={to}
    key={text}
    activeClassName="font-bold"
    className="block hover:text-purple md:inline-block mx-8 md:mx-4 mb-4 md:mb-0"
  >
    {text}
  </Link>
);
NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const NavExternalLink = ({ to, text }) => (
  <a
    href={to} target="_blank" rel="noreferrer" key={text}
    className="block hover:text-purple md:inline-block mx-8 md:mx-4 mb-4 md:mb-0"
  >
    {text}
  </a>
);
NavExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Header;
